import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from '../services/api';
import { chunkArray } from '../utils/chunkArray';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

const BATCH_SIZE = 10; // Number of accounts to process in each batch
const STARTING_BALANCE = 1000000; // Starting balance for P/L and % return calculation
const BLUR_NAMES = false; // Option to blur names for ranks greater than 5

function Leaderboard() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWithAuth('/api/v1beta1/list_accounts');
        if (data && data.accounts) {
          const accountsData = data.accounts;
          const accountBatches = chunkArray(accountsData, BATCH_SIZE);
          const accountsWithBalances = [];

          for (let batch of accountBatches) {
            const balanceRequests = batch.map((account) =>
              fetchWithAuth('/api/v1beta1/list_account_balances', {
                body: {
                  name: account.name,
                },
              })
            );

            const balances = await Promise.allSettled(balanceRequests);

            batch.forEach((account, index) => {
              const balanceResult = balances[index];
              if (balanceResult.status === 'fulfilled') {
                const usdBalance = balanceResult.value?.balances?.USD?.balance || '0';
                const balance = parseFloat(usdBalance);
                const priorityWeight = parseFloat(account.priority_weight || '0');
                const profitLoss = balance - STARTING_BALANCE;
                const percentReturn = ((profitLoss / STARTING_BALANCE) * 100);

                accountsWithBalances.push({
                  ...account,
                  balance,
                  priorityWeight,
                  profitLoss,
                  percentReturn,
                });
              } else {
                console.error(`Failed to fetch balance for ${account.name}:`, balanceResult.reason);
                accountsWithBalances.push({
                  ...account,
                  balance: 0,
                  priorityWeight: 0,
                  profitLoss: -STARTING_BALANCE,
                  percentReturn: -100,
                });
              }
            });
          }

          accountsWithBalances.sort((a, b) => b.balance - a.balance);

          const rankedAccounts = accountsWithBalances.map((account, index) => ({
            ...account,
            rank: index + 1,
          }));

          setAccounts(rankedAccounts);
        } else {
          throw new Error('Invalid data format received from API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  // Helper function to format numbers with commas
  const formatNumber = (number) => {
    if (isNaN(number)) return '0.00';
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // Helper function to determine the color of the P/L and % Return text
  const getTextColor = (value) => {
    return value >= 0 ? 'green' : 'red';
  };

  // Helper function to determine if the name should be blurred
  const getDisplayName = (account) => {
    if (BLUR_NAMES && account.rank > 5) {
      return <span style={{ filter: 'blur(5px)' }}>Hidden</span>;
    }
    return account.display_name;
  };

  return (
    <Container>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        Leaderboard
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="leaderboard table">
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>User</TableCell>
              <TableCell align="right">Account Balance</TableCell>
              <TableCell align="right">P/L</TableCell>
              <TableCell align="right">% Return</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.rank}</TableCell>
                <TableCell>{getDisplayName(account)}</TableCell>
                <TableCell align="right">${formatNumber(account.balance)}</TableCell>
                <TableCell
                  align="right"
                  style={{ color: getTextColor(account.profitLoss) }}
                >
                  ${formatNumber(account.profitLoss)}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ color: getTextColor(account.percentReturn) }}
                >
                  {formatNumber(account.percentReturn)}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Leaderboard;
