import React, { useEffect, useState } from 'react';
import authService from './services/AuthService';
import Leaderboard from './components/Leaderboard';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await authService.login();
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error during initial authentication:', error);
      }
    };

    initializeAuth();
  }, []);

  if (!isAuthenticated) {
    return <p>Loading...</p>; // Display loading while authenticating
  }

  return (
    <div className="App">
      <Leaderboard />
    </div>
  );
}

export default App;
