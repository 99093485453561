// src/services/AuthService.js

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  constructor() {
    this.accessToken = null;
    this.tokenExpiryTime = null;
    this.refreshToken = null;
    this.refreshTokenExpiryTime = null;
    this.refreshTimeout = null; // Timeout to automatically refresh token
  }

  async login() {
    const url = `${API_URL}/auth/v1beta1/login`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const data = {
      username: process.env.REACT_APP_API_USERNAME,
      password: process.env.REACT_APP_API_PASSWORD,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Authentication failed: ${response.statusText}`);
      }

      const result = await response.json();
      this.setTokens(result);
      this.scheduleTokenRefresh();
    } catch (error) {
      console.error('Error during authentication:', error);
      throw error;
    }
  }

  setTokens(tokenData) {
    this.accessToken = tokenData.access_token;
    this.tokenExpiryTime = new Date(tokenData.access_expiration_time).getTime();
    this.refreshToken = tokenData.refresh_token;
    this.refreshTokenExpiryTime = new Date(tokenData.refresh_expiration_time).getTime();
  }

  async refreshAccessToken() {
    if (!this.refreshToken || Date.now() >= this.refreshTokenExpiryTime) {
      console.error('Refresh token is expired or missing.');
      throw new Error('Unable to refresh token, please re-login.');
    }

    const url = `${API_URL}/auth/v1beta1/login`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const data = {
      refresh_token: this.refreshToken,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Token refresh failed: ${response.statusText}`);
      }

      const result = await response.json();
      this.setTokens(result);
      this.scheduleTokenRefresh();
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  }

  scheduleTokenRefresh() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    const expiresIn = this.tokenExpiryTime - Date.now() - 60000; // Refresh 1 minute before expiration
    if (expiresIn > 0) {
      this.refreshTimeout = setTimeout(() => {
        this.refreshAccessToken().catch((error) => {
          console.error('Failed to refresh token:', error);
        });
      }, expiresIn);
    }
  }

  getAccessToken() {
    if (this.tokenExpiryTime && Date.now() >= this.tokenExpiryTime) {
      this.accessToken = null;
      this.tokenExpiryTime = null;
    }
    return this.accessToken;
  }

  logout() {
    // Clear all tokens and any refresh timeouts
    this.accessToken = null;
    this.tokenExpiryTime = null;
    this.refreshToken = null;
    this.refreshTokenExpiryTime = null;
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }
}

const authService = new AuthService();
export default authService;
