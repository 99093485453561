// src/services/api.js
import authService from './AuthService';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Fetch data from the API with the access token.
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {object} options - Additional fetch options.
 */
export async function fetchWithAuth(endpoint, options = {}) {
    try {
      let token = authService.getAccessToken();
      if (!token) {
        // Attempt to refresh or re-authenticate
        try {
          await authService.refreshAccessToken();
          token = authService.getAccessToken();
        } catch (error) {
          console.error('Error refreshing token:', error);
          // If refresh fails, re-login
          await authService.login();
          token = authService.getAccessToken();
        }
      }
  
      const headers = {
        Authorization: `${token}`, // Adding "Bearer" prefix is often required for tokens
        'Content-Type': 'application/json',
      };
  
      const response = await fetch(`${API_URL}${endpoint}`, {
        ...options,
        method: 'POST',
        headers: headers,
        body: JSON.stringify(options.body),
      });
  
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  